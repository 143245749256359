import React, { useState } from "react";
import { navigate } from "gatsby";

const ToolSuggestionForm = (props) =>
{
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [isSuggestionValid, setIsSuggestionValid] = useState(true);

  const handleSubmit = (e) =>
  {
    e.preventDefault();

    const suggestionValidationState = suggestion !== null && suggestion.length !== 0;
    setIsSuggestionValid(suggestionValidationState);

    if (suggestionValidationState)
    {
      props.submitToolSuggestion(name, email, suggestion);

      navigate(`/tools/suggest/result`);
    }
  };

  return (
    <form>
      <div
        className = "mb-6"
      >
        <label
          className="block font-bold mb-2 text-xs uppercase"
          htmlFor="name"
        >
          Name
        </label>

        <div
          className = "px-3 py-2"
        >
          <input
            id = "name"
            className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
            type = "text"
            onChange = { e => { setName(e.target.value); } }
          />
        </div>
      </div>
      <div
        className = "mb-6"
      >
        <label
          className="block font-bold mb-2 text-xs uppercase"
          htmlFor="email"
        >
          Email
        </label>

        <div
          className = "px-3 py-2"
        >
          <input
            id = "email"
            className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full"
            type = "text"
            onChange = { e => { setEmail(e.target.value); } }
          />
        </div>
      </div>
      <div
        className = "mb-6"
      >
        <label
          className="block font-bold mb-2 text-xs uppercase"
          htmlFor="suggestion"
        >
          Suggestion
        </label>

        <div
          className = "px-3 py-2"
        >
          <textarea
            id = "suggestion"
            className="appearance-none block border-gray-400 border px-3 py-2 rounded text-gray-700 w-full resize-none"
            type = "text"
            rows = "5"
            placeholder = "A short description of the Tool you would like to see added to the LawChest."
            onChange = { e => { setSuggestion(e.target.value); } }
          >
          </textarea>
        </div>
        {
          !isSuggestionValid &&
          <p
            className = "px-3 text-red-500 text-xs italic"
          >
            Please enter your suggestion before submission.
          </p>
        }
      </div>
      <div
        className = "text-center sm:text-left"
      >
        <button
          className = "border-b-4 border-gray-800 hover:border-gray-700 bg-gray-700 hover:bg-gray-600 font-bold px-4 py-2 mr-2 rounded text-sm text-white"
          type = "button"
          onClick = { e => handleSubmit(e) }
        >
          Submit
        </button>
      </div>
    </form>
  );
}

export default ToolSuggestionForm;